import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { RTKQueryWrapper } from "components/DataFetchWrapper/RTKQueryWrapper";
import { DataGridTable } from "components/Tables/DataGridTable";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { green, red } from '@mui/material/colors';

const CheckResultDisplay = ({ runResult, checks, selectedCheck }) => {
  const [showRawData, setShowRawData] = useState(false);  // State to toggle raw or result details
  const [open, setOpen] = useState(false); // Dialog open state
  const [modalContent, setModalContent] = useState(""); // Content for dialog

  const handleToggle = (event) => {
    setShowRawData(event.target.checked);
  };

  const handleOpen = (content) => {
    setModalContent(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const processData = (data) => {
    if (!data || typeof data !== 'object' || !data.rows || !data.columns) {
      return { rows: [], columns: [] };
    }
  
    // Customize columns only for specific types
    const columns = data.columns.map(column => {
      // Check if the column needs a custom renderCell
      if (column.type === 'boolean') {
        return {
          ...column,
          renderCell: (params) => (
            params.value ? (
              <CheckIcon sx={{ color: green[500] }} />
            ) : (
              <CloseIcon sx={{ color: red[500] }} />
            )
          ),
        };
      }
  
      if (column.type === 'date' || column.type === 'datetime') {
        return {
          ...column,
          renderCell: (params) => {
            const value = params.value;
            if (value instanceof Date) {
              return value.toLocaleDateString(); // Adjust format if needed
            }
            return value;
          },
        };
      }
      if (column.field === 'failed_check_explanation') {
        return {
          ...column,
          renderCell: (params) => (
            <span
              onClick={() => handleOpen(params.value)}
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            >
              {params.value}
            </span>
          ),
        };
      }
  
      // For other columns, return as-is to preserve any existing renderCell, valueFormatter, or decimals
      return column;
    });
  
    return { rows: data.rows, columns };
  };
  

  const formatLastUpdated = (dateString) => {
    if (!dateString) return "N/A";
    try {
      // Trim milliseconds if present
      const trimmedDateString = dateString.split('.')[0] + 'Z';
      const formattedDate = new Date(trimmedDateString);
      return formattedDate.toLocaleString();
    } catch (error) {
      return "Invalid Date";
    }
  };


  if (!runResult) return null;

  return (
    <Box sx={{ mt: 4 }}>
      <h2>Run Result for {checks.find(check => check.id === selectedCheck)?.name}:</h2>

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100px',
            height: '50px',
            backgroundColor: runResult.check_passed ? '#92D050' : '#F92727',
            color: 'white',
            borderRadius: '12px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            flexShrink: 0, // Prevent shrinking when adding the text next to it
          }}
        >
          {runResult.check_passed ? 'Passed' : 'Failed'}
        </Box>

        {/* Spacing and ensuring it stays on one line */}
        <Box
          sx={{
            ml: 20,
            fontSize: '16px',
            fontStyle: 'italic',
            whiteSpace: 'nowrap', // Prevent wrapping
          }}
        >
          Check last updated: {formatLastUpdated(runResult.last_updated)} (server time, UTC)
        </Box>
      </Box>

      {/* Toggle for raw vs result details */}
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <span>Show failed records</span>
        <FormControlLabel
          control={
            <Switch
              checked={showRawData}
              onChange={handleToggle}
              color="primary"
            />
          }
          labelPlacement="start"
        />
        <span>Show all records</span>
      </Box>

      {/* Data table */}
      <Box sx={{ mt: 4 }}>
        <RTKQueryWrapper query={{ data: showRawData ? runResult.raw_result_details : runResult.result_details }}>
          {(data) => (
            <DataGridTable
              rows={processData(data).rows}
              columns={processData(data).columns}
              pageSize={50}
              pagination={true}
              paginationMode="client"
              rowsPerPageOptions={[50]}
              id={"HealthCheckTable"}
            />
          )}
        </RTKQueryWrapper>
      </Box>
      {/* Dialog for detailed explanations */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Failed Check Explanation</DialogTitle>
        <DialogContent>
          <pre>{modalContent}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};


export default CheckResultDisplay;